import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { position, PositionProps } from "styled-system";

export default function GlobalDeclaration() {
  return (
    <Global
      styles={css`
        * {
          // font-family: Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif;
          webkit-box-sizing: border-box;
          moz-box-sizing: border-box;
          box-sizing: border-box;
          }
          html,
          body {
            margin: 0;
           }
          #__next {
            display: relative;
            /* align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            overflow-y: scroll;
            top: 0px;
            bottom: 0px;
            left: 0px;
            
          }

  /* width */
  ::-webkit-scrollbar {
  width: 10px;
}

/* Track */

  ::-webkit-scrollbar-track {
  background: none; 
}

/* Handle */

  ::-webkit-scrollbar-thumb {
  background: #888; 
border-radius: 10px;
}

  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  
  background: #555; 
} 
        `}
    />
  );
}

type ContainerProps = PositionProps;

export const Container = styled.div<ContainerProps>`
  ${position}
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  // position: absolute;
  // overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
`;
