export default {
  text: "#000",
  background: "#fff",
  primary: "#07c",
  secondary: "#30c",
  muted: "#f6f6f6",
  pink: ["#EFAEAE", "#F1DADA"],
  gray: ["#414141", "#9F9F9F", "#E7E7E7", "#F9F9F9"],
  green: ["#c9d0c2"],
};
