import spaces from "./spaces";
import typography from "./typography";
import colors from "./colors";
import styles from "./styles";
import borders from "./borders";

export default {
  breakpoints: ["500px", "600px", "700px"],
  // spaces,
  typography,
  colors,
  styles,
  borders,
};
