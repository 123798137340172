export default {
  fonts: {
    body: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    // 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [9, 12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
};
