import "normalize.css";
import { AppProps } from "next/app";
// NOTE: Do not move the styles dir to the src.
// They are used by the Netlify CMS preview feature.
//import "../../public/styles/global.css";
// import "../../public/styles/global.css";
import { ThemeProvider } from "@emotion/react";
import Global, { Container } from "../components/Global";
import theme from "../theme";
import * as Sentry from "@sentry/node";
import { Integrations } from "@sentry/tracing";
import { AnimatePresence } from "framer-motion";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <Container bottom={["auto", "0px"]}>
        <Global />
        <AnimatePresence exitBeforeEnter>
          <Component {...pageProps} key={router.pathname} />
        </AnimatePresence>
      </Container>
    </ThemeProvider>
  );
}
